import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_CLASS_TREE_DATA } from '../graphql/queries/general';

import mapUrbanClassTreeKeyToParam from '../data/mapUrbanClassTreeKeyToParam';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/brazil';

const customSubmoduleProps = {
  coverage_main: {
    year: 'activeYear',
    legend: 'activeClassTreeOptionValue',
  },
  organic_carbon_stock: {
    year: 'activeYear',
  },
};

export default function useGenericMapStatistics(params) {
  const {
    activeBaseClassTreeNodeIds,
    activeYear,
    activeClassTreeNodeIds,
    activeClassTreeOptionValue,
    activeSubmodule,
    crossingTerritories,
    customViewMode,
    customCrossingValues,
    territories,
    ruralPropertyCode,
    groupType,
    geometry,
  } = params;
  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: activeClassTreeOptionValue,
    },
  });

  const classTreeNodes = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const customProps = customSubmoduleProps[activeSubmodule] || {};
  const parsedCustomParams = _.reduce(
    customProps,
    (obj, paramFormatter, paramKey) => {
      if (_.isFunction(paramFormatter)) {
        obj[paramKey] = paramFormatter(params);
      } else if (_.isString(paramFormatter)) {
        obj[paramKey] = _.get(params, paramFormatter);
      } else {
        return obj;
      }
      return obj;
    },
    {}
  );

  const crossingTerritoriesIds = _.map(crossingTerritories, 'id');
  let parsedParams = {
    initiative: 'brazil',
    pixelValues: _.filter(
      _.map(activeClassTreeNodeIds, (nodeId) =>
        _.get(_.find(classTreeNodes, { id: nodeId }), 'pixelValue')
      ),
      _.isNumber
    ),
    territoryIds: _.map(territories, 'id'),
    ...parsedCustomParams,
  };

  if (!_.isEmpty(crossingTerritoriesIds)) {
    parsedParams['crossedTerritoryIds'] = crossingTerritoriesIds;
  }

  if (groupType === 'car' && ruralPropertyCode) {
    parsedParams['propertyCode'] = ruralPropertyCode;
  }
  
  let statisticsParams = {
    initiative: 'brasil',
    fromYear: 1985,
    toYear: parsedParams.year,
    pixelValues: parsedParams.pixelValues,
    territoryIds: parsedParams.territoryIds,
    crossedTerritoryIds: parsedParams.crossedTerritoryIds,
    legend: parsedParams.legend,
  };
  let urlPath = '/statistics/coverage_main';

  if (parsedParams.propertyCode) {
    urlPath = '/statistics/coverage_main/property';
    statisticsParams = {
      ...(_.omit(statisticsParams, ['territoryIds', 'crossedTerritoryIds'])),
      propertyCode: parsedParams.propertyCode,
    };
  }

  if (geometry) {
    urlPath = '/statistics/coverage_main/geometry';
    statisticsParams = {
      ...(_.omit(statisticsParams, ['territoryIds', 'crossedTerritoryIds', 'propertyCode'])),
      geometryCoordinates: JSON.stringify(_.get(geometry, 'geometry.coordinates'))
    };
  }

  if (_.includes(activeSubmodule, 'urban') && customViewMode === 'categoryCrossing') {
    urlPath = '/statistics/urban_coverage_crossing';
    _.each(customCrossingValues, (value) => {
      const [classTreeKey, pixelValue] = _.split(value, ':');
      statisticsParams[mapUrbanClassTreeKeyToParam[classTreeKey]] = pixelValue;
    });
    statisticsParams['year'] = activeYear;
    statisticsParams = _.omit(statisticsParams, [
      'crossedTerritoryIds',
      'fromYear',
      'legend',
      'pixelValues',
      'toYear',
    ]);
  }

  if (['soil_texture'].includes(activeSubmodule)) {
    urlPath = '/statistics/' + activeClassTreeOptionValue;

    if (!_.isEmpty(_.compact(activeBaseClassTreeNodeIds))) {
      statisticsParams['depth'] = _.first(activeBaseClassTreeNodeIds);
    }

    statisticsParams['pixelValues'] = _.filter(
      _.map(activeClassTreeNodeIds, (nodeId) =>
        _.get(_.find(classTreeNodes, { id: nodeId, level: 1 }), 'pixelValue')
      ),
      _.isNumber
    );

    statisticsParams = _.omit(statisticsParams, [
      'crossedTerritoryIds',
      'fromYear',
      'legend',
      'toYear',
    ]);
  }

  if ([
    'agriculture_agricultural_use',
    'agriculture_irrigation',
    'agriculture_irrigation_cultivation_frequency',
    'pasture_productivity',
    'pasture_vigor',
    'pasture_age',
  ].includes(activeSubmodule)) {
    urlPath = '/statistics/' + activeSubmodule;
    statisticsParams['fromYear'] = _.first(activeYear);
    statisticsParams['toYear'] = _.last(activeYear);
  }

  if ([
    'organic_carbon_stock',
    'agriculture_irrigation_cultivation_frequency',
    'pasture_age',
  ].includes(activeSubmodule)) {
    urlPath = '/statistics/' + activeClassTreeOptionValue;
  }

  const statisticsUrl = `${ API_URL }${ urlPath }?${ qs.stringify(
    statisticsParams,
    { arrayFormat: 'repeat' }
  ) }`;

  useEffect(() => {
    if (
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'crossedTerritoryIds')) ||
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'propertyCode')) ||
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'geometryCoordinates')) ||
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'depth')) ||
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'organic_carbon')) ||
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'agriculture')) ||
      (_.includes(statisticsUrl, 'pixelValues') && _.includes(statisticsUrl, 'pasture')) ||
      (_.includes(statisticsUrl, 'urban_coverage_crossing'))
    ) {
      setLoading(true);
      fetch(statisticsUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }
  }, [statisticsUrl]);

  return {
    loading,
    data,
    pixelValues: parsedParams.pixelValues
  };
}
