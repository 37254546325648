import _ from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_CLASS_TREE_DATA } from '../graphql/queries/general';

import mapUrbanClassTreeKeyToParam from '../data/mapUrbanClassTreeKeyToParam';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/brazil';

const customSubmoduleProps = {
  coverage_main: {
    year: 'activeYear',
    legend: 'activeClassTreeOptionValue',
  },
  coverage_changes: {
    pixelValues: 'activeClassTreeNodeIds',
    fromYear: ({ yearRange }) => _.first(_.split(yearRange, '-')),
    toYear: ({ yearRange }) => _.last(_.split(yearRange, '-')),
  },
  regeneration_age: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  regeneration_annual: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  deforestation_annual: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  deforestation_accumulated: {
    fromYear: ({ activeYear }) => _.first(activeYear),
    toYear: ({ activeYear }) => _.last(activeYear),
  },
  deforestation_frequency: {
    fromYear: ({ activeYear }) => _.first(activeYear),
    toYear: ({ activeYear }) => _.last(activeYear),
  },
  temporal_analysis_number_of_classes: {
    fromYear: ({ activeYear }) => _.first(activeYear),
    toYear: ({ activeYear }) => _.last(activeYear),
    level: ({ activeClassTreeOptionValue }) =>
      Number(_.last(_.split(activeClassTreeOptionValue, '_'))),
  },
  temporal_analysis_stable_areas: {
    fromYear: ({ activeYear }) => _.first(activeYear),
    toYear: ({ activeYear }) => _.last(activeYear),
    level: ({ activeClassTreeOptionValue }) =>
      Number(_.last(_.split(activeClassTreeOptionValue, '_'))),
  },
  temporal_analysis_number_of_changes: {
    fromYear: ({ activeYear }) => _.first(activeYear),
    toYear: ({ activeYear }) => _.last(activeYear),
    className: ({ activeClassTreeOptionValue }) =>
      activeClassTreeOptionValue.replace(
        'temporal_analysis_number_of_changes_',
        ''
      ),
  },
  mining_main: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  irrigation_main: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  pasture_vigor: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  pasture_productivity: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  pasture_age: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  pasture_transitions: {
    fromYear: ({ yearRange }) => _.first(_.split(yearRange, '-')),
    toYear: ({ yearRange }) => _.last(_.split(yearRange, '-')),
  },
  environmental_analysis: {
    year: 'activeYear',
    environmentalAnalysis: ({ activeClassTreeOptionValue }) =>
      activeClassTreeOptionValue.replace(
        'environmental_analysis_',
        ''
      ),
    environmentalAnalysisValue: ({ activeBaseClassTreeNodeIds }) =>
      _.isEmpty(activeBaseClassTreeNodeIds) ?
        null :
        _.first(activeBaseClassTreeNodeIds)
  },
  coverage_quality_main: {
    year: 'activeYear',
  },
  coverage_3d: {
    year: 'activeYear',
  },
  urban_coverage: {
    year: 'activeYear',
    legend: 'activeClassTreeOptionValue',
  },
  urban_transitions: {
    pixelValues: 'activeClassTreeNodeIds',
    fromYear: ({ yearRange }) => _.first(_.split(yearRange, '-')),
    toYear: ({ yearRange }) => _.last(_.split(yearRange, '-')),
  },
  organic_carbon_stock: {
    year: 'activeYear'
  },
  soil_texture: {
    depth: ({ activeBaseClassTreeNodeIds }) => _.first(activeBaseClassTreeNodeIds), 
  },
  agriculture_agricultural_use: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  agriculture_irrigation: {
    year: ({ activeYear }) => _.last(activeYear),
  },
  agriculture_irrigation_cultivation_frequency: {
    year: ({ activeYear }) => _.last(activeYear),
  },
};

const mapCustomModulePathname = {
  'environmental_analysis': 'coverage_main',
  'coverage_quality_main': 'coverage_quality',
};

export default function useGenericMapLayer(params) {
  const {
    activeBaseClassTreeNodeIds,
    activeClassTreeNodeIds,
    activeClassTreeOptionValue,
    crossingTerritories,
    customViewMode,
    customCrossingValues,
    submoduleContentKey,
    territories,
    ruralPropertyCode,
    groupType,
    zoom,
    geometry,
  } = params;

  const hasBaseValueSelected = !_.isEmpty(_.compact(activeBaseClassTreeNodeIds)) && !['soil_texture'].includes(submoduleContentKey);

  const { data: defaultClassTreeData, loading: loadingDefaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default'
    },
  });
  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: activeClassTreeOptionValue,
    },
    skip: !activeClassTreeOptionValue
  });

  const defaultClassTreeNodes = _.get(defaultClassTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const classTreeNodes = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const customProps = customSubmoduleProps[submoduleContentKey] || {};
  const parsedCustomParams = _.reduce(
    customProps,
    (obj, paramFormatter, paramKey) => {
      if (_.isFunction(paramFormatter)) {
        obj[paramKey] = paramFormatter(params);
      } else if (_.isString(paramFormatter)) {
        obj[paramKey] = _.get(params, paramFormatter);
      } else {
        return obj;
      }
      return obj;
    },
    {}
  );

  const crossingTerritoriesIds = _.map(crossingTerritories, 'id');
  let parsedParams = {
    initiative: 'brazil',
    pixelValues: _.filter(
      _.map(activeClassTreeNodeIds, (nodeId) =>
        _.get(_.find(!hasBaseValueSelected ? classTreeNodes : defaultClassTreeNodes, { id: nodeId }), 'pixelValue')
      ),
      _.isNumber
    ),
    territoryIds: _.map(territories, 'id'),
    ...parsedCustomParams,
  };

  if (!_.isEmpty(crossingTerritoriesIds)) {
    parsedParams['crossedTerritoryIds'] = crossingTerritoriesIds;
  }

  if (groupType === 'car' && ruralPropertyCode) {
    parsedParams['propertyCode'] = ruralPropertyCode;
  }

  if (parsedParams['environmentalAnalysisValue']) {
    const nodeId = parsedParams['environmentalAnalysisValue'];
    const nodePixelValue = _.get(_.find(classTreeNodes, { id: nodeId }), 'pixelValue');

    parsedParams['environmentalAnalysisValue'] = nodePixelValue;
  }

  if (submoduleContentKey === 'mining_main') {
    parsedParams['zoom'] = zoom;
  }

  if (geometry) {
    parsedParams['geometryCoordinates'] = JSON.stringify(_.get(geometry, 'geometry.coordinates'));
  }

  let mapUrl = `${ API_URL }/maps/${ mapCustomModulePathname[submoduleContentKey] || submoduleContentKey }?${qs.stringify(
    parsedParams,
    { arrayFormat: 'repeat' }
  )}`;

  if (activeClassTreeOptionValue === 'agriculture_irrigation_cultivation_frequency_average') {
    // TODO: Remove the fixed value
    parsedParams['fromYear'] = 2017;
    parsedParams['toYear'] = 2023;
    parsedParams = _.omit(parsedParams, [
      'year',
    ]);
  }

  if (['organic_carbon_stock', 'soil_texture', 'agriculture_irrigation_cultivation_frequency', 'pasture_age'].includes(submoduleContentKey)) {
    mapUrl = `${ API_URL }/maps/${ activeClassTreeOptionValue }?${qs.stringify(
      parsedParams,
      { arrayFormat: 'repeat' }
    )}`;
  }
  
  if (_.includes(submoduleContentKey, 'urban') && customViewMode === 'categoryCrossing') {
    _.each(customCrossingValues, (value) => {
      const [classTreeKey, pixelValue] = _.split(value, ':');
      parsedParams[mapUrbanClassTreeKeyToParam[classTreeKey]] = pixelValue;
    });
    parsedParams = _.omit(parsedParams, [
      'legend',
      'pixelValues',
    ]);

    mapUrl = `${ API_URL }/maps/urban_coverage_crossing?${qs.stringify(
      parsedParams,
      { arrayFormat: 'repeat' }
    )}`;
  }

  useEffect(() => {
    if (
      !loadingDefaultClassTreeData &&
      !loadingClassTreeData && (
        _.includes(mapUrl, 'pixelValues') ||
        _.includes(mapUrl, 'PixelValue') ||
        _.includes(mapUrl, 'coverage_quality') ||
        _.includes(mapUrl, 'coverage_3d')
      )
    ) {
      setLoading(true);
      fetch(mapUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.text())
        .then((data) => {
          setUrl(data);
          setLoading(false);
        });
    } else {
      setUrl(null);
      setLoading(false);
    }
  }, [mapUrl, loadingDefaultClassTreeData, loadingClassTreeData]);

  return {
    loading,
    url,
  };
}
