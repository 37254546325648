import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CircularProgress from '@material-ui/core/CircularProgress';

import ClassTreesControl from './components/ClassTreesControl';
import ClassLegendList from './components/ClassLegendList';
import { updateStateCache as uncurriedUpdateStateCache } from '../../../../../../utils/apollo';

import usePrevious from '../../../../../../hooks/usePrevious';

import getCustomClient from '../../../../../../lib/getCustomClient';

import customClassTrees from './customClassTrees';

import {
  GET_BASE_DATA,
  GET_CLASSES_LEVELS_LIST,
  GET_CLASS_TREE_DATA,
} from './query';

import styles from './GenericClasses.module.scss';

const mapClassTreeKeyToDefaultLevels = {
  'soil_texture_sand': [1],
  'deforestation_annual_data': [1, 2],
  'regeneration_annual_data': [1, 2],
  'regeneration_accumulated_data': [1, 2],
  'mining_annual': [1],
};

const degrationPixelValues = [
  // Floresta
  3, // Formação Florestal
  4, // Formação Savânica
  5, // Mangue
  6, // Floresta Alagável
  49, // Restinga Arbórea
  // Formação Natural não Florestal
  11, // Campo Alagado e Área Pantanosa
  12, // Formação Campestre
  50, // Restinga Herbácea"
];

const mapCustomClassTreeKeyToDefaultValue = {
  'soil_texture_default': '000_030',
  'soil_texture_textural': '000_030',
};

const mapClassTreeOptionValueToCustomClassTreeKey = {
  'soil_texture_sand': 'soil_texture_default',
  'soil_texture_silt': 'soil_texture_default',
  'soil_texture_clay': 'soil_texture_default',
  'soil_texture_textural_group': 'soil_texture_default',
  'soil_texture_textural_subgroup': 'soil_texture_default',
  'soil_texture_texture_class': 'soil_texture_textural',
};

export default function GenericClasses({
  clientType,
  hideLegendIndex,
  hideParentToggle,
  moduleId,
  submoduleKey,
  hideLevelsControl,
  secondaryClassTreeKey,
  hasCustomClassTree,
  hideNestedNodes = false,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const client = useApolloClient();
  const hasBaseMode = !!secondaryClassTreeKey || hasCustomClassTree;
  const [baseClassTreeNodes, setBaseClassTreeNodes] = useState(null);
  const updateStateCache = _.partial(uncurriedUpdateStateCache, client);
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const { data: classesTreesData } = useQuery(GET_CLASSES_LEVELS_LIST, {
    variables: {
      moduleId,
      submoduleKey
    },
    client: getCustomClient(clientType)
  });

  const activeClassTreeNodeIds = _.get(baseData, 'app.baseParams.activeClassTreeNodeIds');
  const isOnDegradationModule = _.includes(submoduleKey, 'degradation');
  const degradationType = _.get(baseData, 'app.baseParams.degradationType');
  const degradationFragmentationIsolationValue = _.get(baseData, 'app.baseParams.degradationFragmentationIsolationValue');
  const prevDegradationFragmentationIsolationValue = usePrevious(degradationFragmentationIsolationValue);
  const isOnDegradationCrossingMode = hasBaseMode && degradationType === 'crossing';

  const isOnInitialRenderingWithQueryParams = !_.isEmpty(activeClassTreeNodeIds) &&
    _.isUndefined(classesTreesData);

  const classTrees = _.get(classesTreesData, 'submoduleByModuleIdAndKey[0].submoduleTrees');
  const activeClassTreeOptionValue = _.get(baseData, 'app.baseParams.activeClassTreeOptionValue');
  const activeBaseClassTreeNodeIds = _.get(baseData, 'app.baseParams.activeBaseClassTreeNodeIds');
  const activeBaseClassTreeNodeId = _.first(activeBaseClassTreeNodeIds);

  const { data: classTreeData, loading: loadingClassTreeData, refetch: refetchClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: activeClassTreeOptionValue
    },
    skip: !activeClassTreeOptionValue,
    client: getCustomClient(clientType)
  });
  let classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi') || [];

  // Filter class tree nodes only when module is degradation
  if (hasBaseMode && isOnDegradationModule && _.get(classTreeData, 'classTreeByKey[0].key') === 'default') {
    classTreeLevelsList = _.chain(classTreeLevelsList)
      .filter((node) => _.includes(degrationPixelValues, _.get(node, 'pixelValue')))
      .map((node) => {
        const parentNode = _.find(classTreeLevelsList, { id: node.parentId });

        return [parentNode, node];
      })
      .flatten()
      .orderBy('id', 'asc')
      .uniqBy('id')
      .value();
  }

  if (hideNestedNodes) {
    classTreeLevelsList = _.filter(classTreeLevelsList, { level: 1 });
  }

  useEffect(() => {
    if (!hasCustomClassTree) {
      setBaseClassTreeNodes(null);
    }
  }, [submoduleKey, hasCustomClassTree]);

  useEffect(() => {
    if (hasCustomClassTree && activeClassTreeOptionValue) {
      const customClassTreeKey = mapClassTreeOptionValueToCustomClassTreeKey[activeClassTreeOptionValue];
      const nodes = customClassTrees[customClassTreeKey];
      const classTreeInitialValue = mapCustomClassTreeKeyToDefaultValue[customClassTreeKey];

      setBaseClassTreeNodes(nodes);
      updateStateCache(
        'app.baseParams',
        [ 'activeBaseClassTreeNodeIds', [classTreeInitialValue] ]
      );
    }
  }, [activeClassTreeOptionValue, hasCustomClassTree]);

  useEffect(() => {
    if (!prevDegradationFragmentationIsolationValue && degradationFragmentationIsolationValue) {
      refetchClassTreeData({
        classTreeKey: secondaryClassTreeKey
      });
    } else if (prevDegradationFragmentationIsolationValue && !degradationFragmentationIsolationValue) {
      refetchClassTreeData({
        classTreeKey: activeClassTreeOptionValue
      });
    }
  }, [prevDegradationFragmentationIsolationValue, degradationFragmentationIsolationValue]);

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    const activeTreeIsInCurrentSubmoduleTrees = _.some(
      classTrees,
      { classTree: { key: activeClassTreeOptionValue }}
    );

    if (baseData && classesTreesData && (!activeClassTreeOptionValue || !activeTreeIsInCurrentSubmoduleTrees)) {
      const firstTree = _.get(classTrees, '[0].classTree');
      const firstTreeKey = _.get(firstTree, 'key');

      updateStateCache('app.baseParams',
        [ 'activeClassTreeOptionValue', firstTreeKey ],
        [ 'activeSubmodule', submoduleKey ]
      );
    }
  }, [baseData, classesTreesData, activeClassTreeOptionValue, submoduleKey]);

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    if (baseData && classTreeData && activeClassTreeOptionValue) {
      let nodeIds = _.map(classTreeLevelsList, 'id');

      if (mapClassTreeKeyToDefaultLevels[activeClassTreeOptionValue] || submoduleKey === 'infrastructure_main') {
        let defaultLevels = mapClassTreeKeyToDefaultLevels[activeClassTreeOptionValue];

        if (submoduleKey === 'infrastructure_main') {
          defaultLevels = [1, 2];
        }

        nodeIds = _.chain(classTreeLevelsList)
          .filter((node) => _.includes(defaultLevels, _.get(node, 'level')))
          .map('id')
          .value();
      } else if (submoduleKey === 'regeneration_annual') {
        nodeIds = _.chain(classTreeLevelsList)
          .filter(({ positionInTree }) => _.first(positionInTree) !== 1)
          .map('id')
          .value();
      }

      updateStateCache('app.baseParams',
        [ 'activeClassTreeNodeIds', nodeIds ]
      );
    }
  }, [classTreeData, activeClassTreeOptionValue]);

  if (!baseData || !classesTreesData) {
    return null;
  }

  if (!activeClassTreeOptionValue) {
    return null;
  }

  const activeClassTree = _.find(
    classTrees, { classTree: { key: activeClassTreeOptionValue } }
  )
  const nodesOfActiveClassTree = classTreeLevelsList;

  const levels = _.sortBy(_.uniq(_.map(nodesOfActiveClassTree, 'level')))
  const activeLevels = _.filter(levels, (levelId) => {
    const idsByLevel = _.map(_.filter(nodesOfActiveClassTree, { level: levelId }), 'id')
    const idsByLevelActive = _(idsByLevel)
      .filter((id) => _.includes(activeClassTreeNodeIds, id))
      .compact()
      .value();

    return _.size(idsByLevelActive) === _.size(idsByLevel);
  })

  const handleActiveTreeOptionChange = (classTreeOption) => {
    const classTreeKey = classTreeOption.value
    const activeClassTree = _.find(
      classTrees, { classTree: { key: classTreeKey } }
    )
    const nodesOfActiveClassTree = classTreeLevelsList;
    let nodeIds = _.map(nodesOfActiveClassTree, 'id')

    if (mapClassTreeKeyToDefaultLevels[classTreeKey]) {
      const defaultLevels = mapClassTreeKeyToDefaultLevels[classTreeKey]
      nodeIds = _.chain(nodesOfActiveClassTree)
        .filter((node) => _.includes(defaultLevels, _.get(node, 'level')))
        .map('id')
        .value()
    }

    setBaseClassTreeNodes(null);
    updateStateCache(
      'app.baseParams',
      [ 'activeClassTreeOptionValue', classTreeKey ],
      [ 'activeClassTreeNodeIds', nodeIds ],
      [ 'activeBaseClassTreeNodeIds', [] ]
    );
  };

  const handleClassTreeNodeToggle = (classTreeNode) => {
    const nodeId = _.get(classTreeNode, 'id')

    if (_.includes(activeClassTreeNodeIds, nodeId)) {
      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        _.without(activeClassTreeNodeIds, nodeId)
      ]);
    } else {
      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        [ ...activeClassTreeNodeIds, nodeId ]
      ]);
    }
  };

  const handleLevelToggle = (levelId) => {
    const idsByLevel = _.map(_.filter(nodesOfActiveClassTree, { level: levelId }), 'id')

    if (_.includes(activeLevels, levelId)) {
      let idsClone = _.clone(activeClassTreeNodeIds);
      idsClone = _.filter(idsClone, (id) => !_.includes(idsByLevel, id))

      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        idsClone
      ]);
    } else {
      const nextIds = _.uniq(_.concat(activeClassTreeNodeIds, idsByLevel))

      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        nextIds
      ]);
    }
  };

  const handleClassTreeNodeShowTooltip = (classTreeNode) => {
    if (isMobile) {
      updateStateCache('app',
        [ 'headerIsVisible', false ],
        [ 'showClassInfo', classTreeNode.id ]
      );
    } else {
      updateStateCache('app',
        [ 'showClassInfo', classTreeNode.id ]
      );
    }
  };

  const handleClassLegendListSelectChange = (_dataKey, id) => {
    if (id) {
      refetchClassTreeData({
        classTreeKey: secondaryClassTreeKey
      });
      updateStateCache('app.baseParams', [
        'activeBaseClassTreeNodeIds',
        [id]
      ]);
    } else {
      refetchClassTreeData({
        classTreeKey: activeClassTreeOptionValue
      });
      updateStateCache('app.baseParams', [
        'activeBaseClassTreeNodeIds',
        []
      ]);
    }

    setBaseClassTreeNodes(baseClassTreeNodes || classTreeLevelsList);
  };

  const classesControlTreeOptions = _.map(classTrees, (classTree) => ({
    label: _.chain(classTree)
      .get('classTree.i18nStrings')
      .find({ language: locale })
      .get('stringValue')
      .value(),
    value: _.get(classTree, 'classTree.key')
  }));

  const baseNodesOptions = _.map((baseClassTreeNodes || classTreeLevelsList), (node) => ({
    label: _.chain(node)
      .get('i18nStrings')
      .find({ language: locale })
      .get('stringValue')
      .value(),
    id: _.get(node, 'id')
  }));

  return (
    <div className={ styles.wrapper }>
      { !isOnDegradationCrossingMode &&
        <ClassTreesControl
          classTreeOptions={ classesControlTreeOptions }
          activeTreeOption={
            _.find(
              classesControlTreeOptions,
              { value: activeClassTreeOptionValue }
            )
          }
          levels={ levels }
          activeLevels={ activeLevels }
          submoduleKey={ submoduleKey }
          onActiveTreeOptionChange={ handleActiveTreeOptionChange }
          onLevelToggle={ handleLevelToggle }
          hideLevelsControl={ hideLevelsControl }
          hasBaseMode={ hasBaseMode }
          baseNodesOptions={ baseNodesOptions }
          activeBaseClassTreeNodeId={ activeBaseClassTreeNodeId }
          onBaseNodeOptionChange={ handleClassLegendListSelectChange }
        />
      }
      { loadingClassTreeData &&
        <div className={ styles.loadingWrapper }>
          <CircularProgress size={ 20 } />
        </div>
      }
      { !loadingClassTreeData &&
        <ClassLegendList
          hideLegendIndex={ hideLegendIndex }
          hideParentToggle={ hideParentToggle }
          hideNestedNodes={ hideNestedNodes }
          classTreeNodes={ nodesOfActiveClassTree }
          activeClassTreeNodeIds={ activeClassTreeNodeIds }
          onClassTreeNodeToggle={ handleClassTreeNodeToggle }
          onClassTreeNodeShowTooltip={ handleClassTreeNodeShowTooltip }
        />
      }
    </div>
  )
}
