import React, { Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';

import { FormattedMessage } from 'react-intl';

import LinearProgress from '@material-ui/core/LinearProgress';

import PieChart from '../../components/PieChart';

import styles from '../../StatisticsBox.module.scss';
import stylesInternal from './UrbanCrossingStatisticsContent.module.scss';

import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

const formatNumber = (value, mantissa = 0) => {
  if (_.isNil(value)) { return '-'; }

  return numbro(value).format({
    thousandSeparated: true,
    mantissa
  });
};

export default function UrbanCrossingStatisticsContent({
  activeYear,
  baseParams,
}) {
  const customCrossingValues = _.get(baseParams, 'customCrossingValues');

  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
  });
  const { data, loading } = statisticsData;

  const totalChart = () => {
    if (!_.isArray(data)) {
      return null;
    }

    const areas = _.get(data, '[0].areas');
    const getAreaByPixel = (pixelId) => {
      return _.get(_.find(areas, { pixelId }), 'value');
    };
    const insideArea = getAreaByPixel(1);
    const outsideArea = getAreaByPixel(2);

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>Valores totais relativos à área urbana</h2>
        <div className={ stylesInternal.bigNumberContainer }>
          <div className={ stylesInternal.bigNumberItem }>
            <p className={ stylesInternal.bigNumberInfo }>Dentro</p>
            <h3 className={ stylesInternal.bigNumber }>{ formatNumber(insideArea, 2) } ha</h3>
          </div>
          <div className={ stylesInternal.bigNumberItem }>
            <p className={ stylesInternal.bigNumberInfo }>Fora</p>
            <h3 className={ stylesInternal.bigNumber }>{ formatNumber(outsideArea, 2) } ha</h3>
          </div>
        </div>
        <PieChart
          hasLegend
          unit="ha"
          serie={{
            title: 'Área urbana',
            data: [
              {
                name: 'Dentro',
                value: getAreaByPixel(1),
                itemStyle: {
                  color: '#FF0100'
                },
              },
              {
                name: 'Fora',
                value: getAreaByPixel(2),
                itemStyle: {
                  color: '#FFCCCC'
                },
              },
            ]
          }}
        />
      </div>
    );
  };

  const renderData = () => {
    return (
      <Fragment>
        { totalChart() }
      </Fragment>
    );
  };

  return (
    <Fragment>
      {/* <div className={ styles.fireModeContentBoxExtra }>
        <div
          className={ styles.fireModeContentBoxTextWrapper }
          dangerouslySetInnerHTML={{
            __html: formatMessage('mapbiomas.warning.degradation_fragmentation.multiple.statistics_note')
          }}
        />
      </div> */}
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { !loading && !data &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { !loading && _.isEmpty(customCrossingValues) &&
        <div className={ styles.infoBox }>
          <p>É necessário selecionar classes para visualizar os dados.</p>
        </div>
      }
      { !loading && data && renderData() }
    </Fragment>
  );
}
