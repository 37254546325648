import React, { Fragment, useState } from 'react';
import _ from 'lodash';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import styles from './CustomSelect.module.scss';

export default function CustomSelect({
  disableClearable = true,
  disabled,
  displayEmpty = false,
  isMultiple = false,
  isLoading = false,
  options = [],
  defaultOrder = false,
  value = '',
  dataKey,
  label,
  placeholder,
  onChange = () => {},
}) {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    if (event) {
      setInputValue(event.target.value);
    }
  };

  const handleAutocompleteChange = (_event, option, reason) => {
    if (reason === 'select-option') {
      setInputValue('');
      onChange(dataKey, option.id);
    } else if (reason === 'clear') {
      setInputValue('');
      onChange(dataKey, null);
    }
  };

  const getSelectedOption = (options, value) => {
    return _.find(options, { id: value }) || '';
  };

  const getSelectedOptionLabel = (options, value) => {
    return _.get(getSelectedOption(options, value), 'label') || '';
  };

  if (!isMobile) {
    let extraProps = {};

    if (isMultiple) {
      extraProps = {
        onInputChange: handleInputChange,
        inputValue: inputValue,
      };
    }

    return (
      <Autocomplete
        id={ `select-${ dataKey }` }
        blurOnSelect={ true }
        // disableClearable={ true }
        disabled={ disabled }
        loading={ isLoading }
        loadingText="Carregando..."
        noOptionsText="Nenhuma opção disponível"
        options={ defaultOrder ? options : _.sortBy(options, 'label') }
        getOptionLabel={ (option) => option.label || '' }
        value={ getSelectedOption(options, value) }
        onChange={ handleAutocompleteChange.bind(this) }
        renderInput={ (params) => (
          <TextField
            { ...params }
            label={ label }
            size="small"
            variant="outlined"
            placeholder={ placeholder }
          />
        ) }
        { ...extraProps }
      />
    );
  } else {
    return (
      <Fragment>
        <InputLabel id={ `select-${ dataKey }` } className={ styles.inputLabel }>{ label }</InputLabel>
        <Select
          displayEmpty={ displayEmpty }
          labelId={ `select-${ dataKey }-label` }
          id={ `select-${ dataKey }` }
          value={ !_.isNil(value) ? value : '' }
          onChange={ onChange.bind(this, dataKey) }
          disabled={ isLoading || disabled }
          placeholder="Selecione uma opção"
        >
          { _.map(_.sortBy(options, 'label'), (option) => {
            return (
              <MenuItem key={ `${ dataKey }-option-${ option.id }` } value={ option.id }>{ option.label }</MenuItem>
            );
          }) }
        </Select>
      </Fragment>
    );
  }
}
