import { gql } from 'graphql.macro';

export const GET_BASE_CLIENT_DATA = gql`
  query {
    app @client {
      activeModule
      activeModuleContent
      showBeforeAndAfterMosaic
      baseParams {
        groupType
        degradationType
        degradationFragmentationIsolationValue
        degradationCrossingValues {
          degradation_fragmentation_edge_area
          degradation_fragmentation_fragment_size
          degradation_fragmentation_isolation_medium_size
          degradation_fragmentation_isolation_distance
          degradation_fragmentation_isolation_big_size
          degradation_fire_frequency
          degradation_fire_time
          degradation_secondary_vegetation_age
          parsedParams
        }
        customViewMode
        customCrossingValues
        territoryType
        territorySubtype
        territoryValueType
        crossingEnabled
        crossingTerritoryType
        territory
        territories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossingTerritories {
          id
          label
          territoryTypeId
          territoryTypeLabel
          territoryBBox
        }
        crossing
        buffer
        yearRange
      }
    }
  }
`;
