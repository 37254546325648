export default [
  {
    "key": "amacro",
    "labelKey": "amacro",
    "color": "#AF175D",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "Municípios do IBGE 1:250.000"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2021"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/AMACRO.zip",
    "index": 7
  },
  {
    "key": "priority_areas_for_biodiversity_conservation",
    "labelKey": "priority_areas_for_biodiversity_conservation",
    "color": "#D5C987",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "MMA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2018"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/AREAS_PRIORITARIAS_DO_MMA_2018.zip",
    "index": 13
  },
  {
    "key": "atlantic_forest_law",
    "labelKey": "atlantic_forest_law",
    "color": "#780439",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "SOS Mata Atlântica (refinado IBGE 1:5.000.000 com base no RADAM 1:1.000.000)"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2016"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/ATLANTIC_FOREST_LAW.zip",
    "index": 8
  },
  {
    "key": "pnrh_level_1_basin",
    "labelKey": "pnrh_level_1_basin",
    "color": "#2980B9",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "ANA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2006"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/BASIN_LEVEL_1_PNRH.zip",
    "index": 5
  },
  {
    "key": "pnrh_level_2_basin",
    "labelKey": "pnrh_level_2_basin",
    "color": "#55F8F8",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "ANA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2006"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/BASIN_LEVEL_2_PNRH.zip",
    "index": 6
  },
  {
    "key": "biome",
    "labelKey": "biome",
    "color": "#EB2F06",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "IBGE"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2019"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/BIOMES.zip",
    "index": 1
  },
  {
    "key": "coastal_marine_zone",
    "labelKey": "coastal_marine_zone",
    "color": "#2532E4",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "IBGE"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2019"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/COASTAL_MARINE_ZONE.zip",
    "index": 4
  },
  {
    "key": "forest_concessions",
    "labelKey": "forest_concessions",
    "color": "#00B050",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "SFB/MMA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2023"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/CONCESSOES_FLORESTAIS.zip",
    "index": 20
  },
  {
    "key": "undesignated_public_forests",
    "labelKey": "undesignated_public_forests",
    "color": "#033300",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "MMA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2022"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/CONCESSOES_FLORESTAIS.zip",
    "index": 12
  },
  {
    "key": "geopark",
    "labelKey": "geopark",
    "color": "#ACFC3A",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "UNESCO"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2023"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/GEOPARQUES.zip",
    "index": 14
  },
  {
    "key": "indigenous_territorie",
    "labelKey": "indigenous_territorie",
    "color": "#6A3D9A",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "FUNAI"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2024"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/INDIGENOUS_TERRITORIES.zip",
    "index": 17
  },
  {
    "key": "legal_amazon",
    "labelKey": "legal_amazon",
    "color": "#A25E7D",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "IBGE"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2022"
      }
    ],
    "downloadUrl": "",
    "index": 9
  },
  {
    "key": "matopiba",
    "labelKey": "matopiba",
    "color": "#5A032A",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "EMBRAPA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2015"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/LEGAL_AMAZON.zip",
    "index": 11
  },
  {
    "key": "state",
    "labelKey": "state",
    "color": "#FBEC00",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "IBGE"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2022"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/POLITICAL_LEVEL_2.zip",
    "index": 2
  },
  {
    "key": "municipality",
    "labelKey": "municipality",
    "color": "#FDBF6F",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "IBGE"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2022"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/POLITICAL_LEVEL_3.zip",
    "index": 3
  },
  {
    "key": "quilombo",
    "labelKey": "quilombo",
    "color": "#7F6B83",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "IBGE"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "censo 2022"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/QUILOMBOS.zip",
    "index": 18
  },
  {
    "key": "biosphere_reserve",
    "labelKey": "biosphere_reserve",
    "color": "#5BFC8C",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "MMA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2022"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/RESERVA_DA_BIOSFERA.zip",
    "index": 15
  },
  {
    "key": "semiarid",
    "labelKey": "semiarid",
    "color": "#360219",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "ANA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "2017"
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/SEMIARID.zip",
    "index": 10
  },
  {
    "key": "settlement",
    "labelKey": "settlement",
    "color": "#FC3AD3",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "INCRA"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": ""
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/SETTLEMENTS.zip",
    "index": 19
  },
  {
    "key": "protected_areas",
    "labelKey": "protected_areas",
    "color": "#5AD34A",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "MMA/I3Geo"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": ""
      }
    ],
    "downloadUrl": "https://storage.googleapis.com/mapbiomas-workspace/INDEXED_TERRITORIES/LULC/BRAZIL/COLLECTION9/WORKSPACE/PROTECTED_AREA.zip",
    "index": 16
  },
  {
    "key": "car_complete_layer",
    "labelKey": "car_complete_layer",
    "color": "#F1C231",
    "type": "wmts",
    "descriptionData": [
      {
        "labelKey": "source",
        "label": "Fonte",
        "value": "SICAR"
      },
      {
        "labelKey": "year",
        "label": "Ano",
        "value": "01/2024"
      }
    ],
    "props": {
      "url": "https://shared-geoserver.mapbiomas.org/geoserver/gwc/service/wmts",
      "layers": "mapbiomas-layers:sicar",
    },
    "downloadUrl": null,
    "index": 21
  },
];
