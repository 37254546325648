import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import styles from './UrbanCrossingClasses.module.scss';

const classItems = [
  {
    id: 'inside_urban_area',
    label: 'Dentro da área urbana',
    color: '#FF0100'
  },
  {
    id: 'outside_urban_area',
    label: 'Fora da área urbana',
    color: '#FFCCCC'
  },
];

export default function UrbanCrossingClasses() {
  return (
    <div className={ styles.wrapper }>
      <h2 className={ styles.title }><FormattedMessage id="mapbiomas.header.classes_control.title" /></h2>
      <ul className={ styles.classList }>
        { _.map(classItems, (item) => {
          return (
            <li
              key={ `class-item-level-${ item.id }` }
              className={ styles.classListItemBase } 
            >
              <div className={ styles.classListItemHeader }>
                <span className={ styles.classListItemColor } style={{ backgroundColor: item.color }} />
                <span className={ styles.classListItemTitle }>
                  <FormattedMessage id={ `mapbiomas.header.urban_classes.${ item.id }` } />
                </span>
              </div>
            </li>
          );
        }) }
      </ul>
    </div>
  );
}
