import React from 'react';
import _ from 'lodash';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';

import CustomSelect from '../../../../components/CustomSelect';

import styles from './CustomClassTreeControl.module.scss';

import { GET_CLASS_TREE_DATA } from '../../../../../../graphql/queries/general';
import {
  GET_BASE_CLIENT_DATA,
} from './query';

export default function CustomClassTreeControl({
  classTreeKey,
  hideLegendIndex = false,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const client = useApolloClient();
  const { data: clientBaseData } = useQuery(GET_BASE_CLIENT_DATA);
  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: classTreeKey
    },
  });
  const classTree = _.get(classTreeData, 'classTreeByKey[0]');

  const baseParams = _.get(clientBaseData, 'app.baseParams');
  const customCrossingValues = _.get(baseParams, 'customCrossingValues');

  const parsedValue = _.last(_.split(_.find(customCrossingValues, (v) => v.startsWith(classTreeKey)), ':'));

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const updateClientAppBaseParamsState = (params) => {
    updateClientAppState({
      baseParams: {
        __typename: 'AppBaseParamsState',
        ...params
      }
    });
  };

  const handleSelectChange = (_key, value) => {
    if (value) {
      const newValue = `${ classTreeKey }:${ value }`;

      updateClientAppBaseParamsState({
        customCrossingValues: [
          ...customCrossingValues,
          newValue
        ]
      });
    } else {
      const nextCustomCrossingValues = _.filter(customCrossingValues, (value) => !_.includes(value, classTreeKey));

      updateClientAppBaseParamsState({
        customCrossingValues: nextCustomCrossingValues
      });
    }
  };

  if (loadingClassTreeData || !classTreeData) {
    return (
      <span className={ styles.loadingProgressWrapper }>
        <CircularProgress size= { 20 } color="secondary" />
      </span>
    );
  }

  const classTreeLabel = _.chain(classTree)
    .get('i18nStrings')
    .find({ language: locale })
    .get('stringValue')
    .value();

  const options = _.map(_.get(classTree, 'mvClassTreeLevelsUi'), (node) => {
    const prettyIndexOfNodeInTree = _.join(node.positionInTree, '.');
    const nodeLabel = _.chain(node)
      .get('i18nStrings')
      .find({ language: locale })
      .get('stringValue')
      .value();
    const indexText = hideLegendIndex ? '' : `${ prettyIndexOfNodeInTree }. `;

    return {
      id: node.pixelValue,
      label: `${ indexText }${ nodeLabel }`
    };
  });

  return (
    <FormControl
      variant="outlined"
      className={ styles.control }
    >
      <CustomSelect
        defaultOrder
        disableClearable={ false }
        options={ options }
        value={ parsedValue }
        dataKey="category"
        label={ classTreeLabel }
        onChange={ handleSelectChange }
        placeholder="Selecione uma classe"
      />
    </FormControl>
  );
}
