import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import PieChart from '../../components/PieChart';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';
import useGenericMapStatistics from '../../../../../../../../hooks/useGenericMapStatistics';

import getCustomClient from '../../../../../../../../lib/getCustomClient';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_CLASS_TREE_DATA,
} from './query';

export default function GEESimpleStatisticsContent({
  activeYear,
  baseParams,
  clientType,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
  unit,
  valueMantissa = 0,
  ruralPropertyCode,
  geometry,
}) {
  const statisticsData = useGenericMapStatistics({
    ...baseParams,
    activeYear,
    ruralPropertyCode,
    geometry
  });
  const { loading, data } = statisticsData;

  const currentYear = _.isArray(activeYear) ? _.last(activeYear) : activeYear;
  const locale = localStorage.getItem('locale') || 'pt-BR';
  const formatMessage = useFormatMessage();

  const [chartsData, setChartsData] = useState(null);

  const { data: classTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: getCustomClient(clientType)
  });

  const parseChartsData = () => {
    const classesLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
    const areas = _.get(data, '[0].areas');

    const classViewChartData = _(areas)
      .map(({ pixelId, value }) => {
        const nodeData = _.find(classesLevelsList, { pixelValue: pixelId });
        const stringList = _.get(nodeData, 'i18nStrings');
        const selectedString = _.find(stringList, { language: locale });
        const className = _.get(selectedString, 'stringValue');

        return {
          ...(nodeData || {}),
          name: className,
          value: value,
          itemStyle: {
            color: _.get(nodeData, 'color')
          },
        };
      })
      .filter({ level: 1 })
      .value();

    return {
      classViewChartData,
      classViewChartDataUnit: null,
    };
  };

  useEffect(() => {
    if (!loading && data) {
      setChartsData(parseChartsData());
    }
  }, [loading]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const renderClassViewChart = () => {
    const data = _.get(chartsData, 'classViewChartData');
    const classViewChartDataUnit = _.get(chartsData, 'classViewChartDataUnit');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title_default" /></h2>
        <PieChart
          serie={{
            title: classViewChartDataUnit || unit || formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data
          }}
          mantissa={ valueMantissa }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const classViewChartData = _.get(chartsData, 'classViewChartData');
    const classViewChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title_default'),
      columns: ['Total'],
      rows: _.map(classViewChartData, (item) => {
        return {
          name: _.get(item, 'name'),
          data: [_.get(item, 'value')],
        };
      }),
      mantissa: valueMantissa
    };

    onShowData([
      classViewChart,
    ]);
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { renderClassViewChart() }
      {/* { renderHistoricalSeriesChart() } */}
      <div id="data-view-button" className={ styles.actionWrapper }>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={ handleShowData }
          className={ styles.actionButton }
        >
          <TableChartIcon />
          <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
        </Button>
      </div>
    </Fragment>
  );
}
