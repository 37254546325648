export default {
  'soil_texture_default': [
    {
      "id": '000_010',
      "color": "#ff0001",
      "classId": 716,
      "childrenIds": [],
      "positionInTree": [
        1
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "0-10 cm",
      "classTreeId": 46,
      "pixelValue": '000_010',
      "i18nStrings": [
        {
          "language": "en",
          "stringValue": "0-10 cm",
          "__typename": "I18nString"
        },
        {
          "language": "pt-BR",
          "stringValue": "0-10 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
    {
      "id": '010_020',
      "color": "#ffaa5f",
      "classId": 717,
      "childrenIds": [],
      "positionInTree": [
        2
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "10-20 cm",
      "classTreeId": 46,
      "pixelValue": '010_020',
      "i18nStrings": [
        {
          "language": "en",
          "stringValue": "10-20 cm",
          "__typename": "I18nString"
        },
        {
          "language": "pt-BR",
          "stringValue": "10-20 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
    {
      "id": '020_030',
      "color": "#32cd32",
      "classId": 718,
      "childrenIds": [],
      "positionInTree": [
        3
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "20-30 cm",
      "classTreeId": 46,
      "pixelValue": '020_030',
      "i18nStrings": [
        {
          "language": "pt-BR",
          "stringValue": "20-30 cm",
          "__typename": "I18nString"
        },
        {
          "language": "en",
          "stringValue": "20-30 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
    {
      "id": '000_020',
      "color": "#32cd32",
      "classId": 718,
      "childrenIds": [],
      "positionInTree": [
        3
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "0-20 cm",
      "classTreeId": 46,
      "pixelValue": '000_020',
      "i18nStrings": [
        {
          "language": "pt-BR",
          "stringValue": "0-20 cm",
          "__typename": "I18nString"
        },
        {
          "language": "en",
          "stringValue": "0-20 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
    {
      "id": '000_030',
      "color": "#32cd32",
      "classId": 718,
      "childrenIds": [],
      "positionInTree": [
        3
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "0-30 cm",
      "classTreeId": 46,
      "pixelValue": '000_030',
      "i18nStrings": [
        {
          "language": "pt-BR",
          "stringValue": "0-30 cm",
          "__typename": "I18nString"
        },
        {
          "language": "en",
          "stringValue": "0-30 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
  ],
  'soil_texture_textural': [
    {
      "id": '000_010',
      "color": "#ff0001",
      "classId": 716,
      "childrenIds": [],
      "positionInTree": [
        1
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "0-10 cm",
      "classTreeId": 46,
      "pixelValue": '000_010',
      "i18nStrings": [
        {
          "language": "en",
          "stringValue": "0-10 cm",
          "__typename": "I18nString"
        },
        {
          "language": "pt-BR",
          "stringValue": "0-10 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
    {
      "id": '000_020',
      "color": "#32cd32",
      "classId": 718,
      "childrenIds": [],
      "positionInTree": [
        3
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "0-20 cm",
      "classTreeId": 46,
      "pixelValue": '000_020',
      "i18nStrings": [
        {
          "language": "pt-BR",
          "stringValue": "0-20 cm",
          "__typename": "I18nString"
        },
        {
          "language": "en",
          "stringValue": "0-20 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
    {
      "id": '000_030',
      "color": "#32cd32",
      "classId": 718,
      "childrenIds": [],
      "positionInTree": [
        3
      ],
      "level": 1,
      "parentId": null,
      "defaultName": "0-30 cm",
      "classTreeId": 46,
      "pixelValue": '000_030',
      "i18nStrings": [
        {
          "language": "pt-BR",
          "stringValue": "0-30 cm",
          "__typename": "I18nString"
        },
        {
          "language": "en",
          "stringValue": "0-30 cm",
          "__typename": "I18nString"
        }
      ],
      "classTreeNode": {
        "classe": {
          "classesTooltips": [],
          "__typename": "Classe"
        },
        "__typename": "ClassTreeNode"
      },
      "__typename": "MvClassTreeLevelUi"
    },
  ]
};
