import React, { useEffect, useState, Fragment } from 'react';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import qs from 'qs';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import CARBox from './components/CARBox';
import ClassLegendDialog from './components/ClassLegendDialog';
import CoordinatesBox from './components/CoordinatesBox';
import DashboardDialog from './components/DashboardDialog';
import MapCanvas from './components/MapCanvas';
import MapboxCanvas from './components/MapboxCanvas';
import MapboxControl from './components/MapboxControl';
import MapControls from './components/MapControls';
import MapPointDialog from './components/MapPointDialog';
import YearTimeline from './components/YearTimeline';
import YearTimelineMultiple from './components/YearTimelineMultiple';
import ShareControl from './components/ShareControl';
import StatisticsBox from './components/StatisticsBox';
import InfrastructureStatisticsBox from './components/InfrastructureStatisticsBox';
import StatisticsDataTableBox from './components/StatisticsDataTableBox';
import TutorialTour from '../../../../components/tutorial/TutorialTour';

import {
  GET_BASE_DATA,
  GET_TERRITORIES_CATEGORIES_LIST,
  GET_TERRITORIES_LIST,
  GET_CLASS_TREE_DATA,
} from './query';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import usePrevious from '../../../../hooks/usePrevious';

import parseQueryParamsToState from '../../../../helpers/parseQueryParamsToState';

import toast from '../../../../utils/toast';

import styles from '../../LandingPage.module.scss';

const MODULES_WITH_INITIAL_YEAR_FIXED = [
  'fire:fire_accumulated',
  'deforestation:deforestation_accumulated',
];

export default function Map({
  activeBaseMap,
  activeLayers,
  layersOpacity,
  location,
}) {
  const client = useApolloClient();
  const formatMessage = useFormatMessage();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [coverageTutorial, setCoverageTutorial] = useState(true);
  const [statisticsData, setStatisticsData] = useState(null);
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA);
  const { data: territoriesCategoriesListData } = useQuery(
    GET_TERRITORIES_CATEGORIES_LIST
  );
  const { data: defaultClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: 'default',
    },
  });
  const defaultClassTreeLevelsList = _.get(
    defaultClassTreeData,
    'classTreeByKey[0].mvClassTreeLevelsUi'
  );
  const [loadCountryTerritoryData, { data: countryTerritoryListData }] =
    useLazyQuery(GET_TERRITORIES_LIST);
  const prevLoadingBaseData = usePrevious(loadingBaseData);
  const activeModule = _.get(baseData, 'app.activeModule');
  const activeModuleContent = _.get(baseData, 'app.activeModuleContent');
  const prevActiveModuleContent = usePrevious(activeModuleContent);
  const activeClassTreeOptionValue = _.get(
    baseData,
    'app.baseParams.activeClassTreeOptionValue'
  );
  const degradationType = _.get(baseData, 'app.baseParams.degradationType');
  const activeSubmodule = _.get(baseData, 'app.baseParams.activeSubmodule');
  const isOnDegradationCrossingMode = degradationType === 'crossing';
  const activeYear = _.get(baseData, 'app.activeYear');
  const baseParams = _.get(baseData, 'app.baseParams');
  const mapboxMode = _.get(baseData, 'app.mapboxMode');
  const transitionClassTreeNodeId = _.get(
    baseData,
    'app.transitionClassTreeNodeId'
  );
  const territoryCategoriesList = _.get(
    territoriesCategoriesListData,
    'territoryCategoriesList'
  );
  const activeSubmoduleHasNoTimeline = _.includes(['soil_texture'], activeSubmodule);

  const headerIsVisible = _.get(baseData, 'app.headerIsVisible');
  const isOnUpdatingWarningMode = _.isString(_.get(baseData, 'app.updatingWarningContentKey'));
  const mapFlyTo = _.get(baseData, 'app.mapFlyTo');
  const mapPointInfo = _.get(baseData, 'app.mapPointInfo');
  const showClassInfo = _.get(baseData, 'app.showClassInfo');
  const showDashboardInfo = _.get(baseData, 'app.showDashboardInfo');
  const showBeforeAndAfterMosaic = _.get(
    baseData,
    'app.showBeforeAndAfterMosaic'
  );
  const timelineLimitsRange = _.get(baseData, 'app.timelineLimitsRange');
  const mapPosition = _.get(baseData, 'app.mapPosition');
  const ruralPropertyCode = _.get(baseData, 'app.ruralPropertyCode');
  const carFeature = JSON.parse(_.get(baseData, 'app.carFeature'));
  const geometry = JSON.parse(_.get(baseData, 'app.geometry'));
  const geometryMode = _.get(baseData, 'app.geometryMode');

  let blockModuleWarningIsVisible = false;

  // Add conditions to show block module warning dialog below
  // if (activeClassTreeOptionValue === 'fire_annual_by_class') {
  //   blockModuleWarningIsVisible = true;
  // }

  const [lat, lng, zoom] = _.split(mapPosition, ',');
  let mapConfigOptions = {};

  if (mapPosition) {
    mapConfigOptions = {
      lat: Number(lat),
      lng: Number(lng),
      zoom: Number(zoom),
    };
  }

  let urlQueryParams = {};

  if (location && location.search) {
    const queryString = location.search.replace('?', '');
    const queryObject = qs.parse(queryString, { comma: true });

    if (queryObject) {
      urlQueryParams = parseQueryParamsToState(queryObject);
    }
  }

  const isOnInitialRenderingWithQueryParams =
    loadingBaseData ||
    (prevLoadingBaseData && !loadingBaseData && !_.isEmpty(urlQueryParams));

  useEffect(() => {
    const confirmation = new URL(window.location.href).searchParams.get(
      'confirmation'
    );
    const login = new URL(window.location.href).searchParams.get('login');
    const passwordUpdate = new URL(window.location.href).searchParams.get(
      'password_update'
    );

    if (confirmation) {
      toast(formatMessage('mapbiomas.toast.account_confirmed'));
    }

    if (login) {
      toast(formatMessage('mapbiomas.toast.login_success'));
    }

    if (passwordUpdate) {
      toast(formatMessage('mapbiomas.toast.password_update'));
    }
  }, []);

  useEffect(() => {
    if (activeModuleContent !== 'coverage:coverage_changes') {
      updateClientAppState({
        showBeforeAndAfterMosaic: false,
      });
    }

    const checkModuleKeyCondition = (moduleKey) => {
      if (_.isUndefined(prevActiveModuleContent)) {
        return activeModuleContent === moduleKey;
      } else {
        return (
          activeModuleContent === moduleKey &&
          !isOnInitialRenderingWithQueryParams
        );
      }
    };

    if (checkModuleKeyCondition('coverage:coverage_main')) {
      updateClientAppState({
        activeBaseMap: 9,
        layersOpacity: 70,
        activeLayers: [],
      });
    } else if (
      checkModuleKeyCondition('regeneration:regeneration_annual') ||
      checkModuleKeyCondition('deforestation:deforestation_annual')
    ) {
      updateClientAppState({
        activeBaseMap: 1,
      });
    } else if (checkModuleKeyCondition('agriculture:agriculture_agricultural_use')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeLayers: ['state'],
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (checkModuleKeyCondition('agriculture:agriculture_irrigation')) {
      updateClientAppState({
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (checkModuleKeyCondition('agriculture:agriculture_irrigation_cultivation_frequency')) {
      updateClientAppState({
        activeYear: [2017, 2023],
        timelineLimitsRange: [2017, 2023],
      });
    } else if (checkModuleKeyCondition('agriculture:agriculture_irrigation_cultivation_frequency')) {
      updateClientAppState({
        activeYear: [2017, 2023],
        timelineLimitsRange: [2017, 2023],
      });
    } else if (checkModuleKeyCondition('infrastructure:infrastructure_main')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: 2020,
        timelineLimitsRange: [1985, 2020],
      });
    } else if (checkModuleKeyCondition('fire:fire_accumulated')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_annual')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_frequency')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_monthly')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: 2023,
        timelineLimitsRange: [1985, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_last')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: 2023,
        timelineLimitsRange: [1986, 2023],
      });
    } else if (checkModuleKeyCondition('fire:fire_size')) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: 2023,
        timelineLimitsRange: [1985, 2023],
      });
    } else if (
      checkModuleKeyCondition(
        'temporal_analysis:temporal_analysis_number_of_classes'
      )
    ) {
      updateClientAppState({
        activeBaseMap: 1,
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (
      checkModuleKeyCondition('deforestation:deforestation_accumulated')
    ) {
      updateClientAppState({
        activeYear: [1986, 2023],
        timelineLimitsRange: [1986, 2023],
      });
    } else if (
      checkModuleKeyCondition('deforestation:deforestation_frequency')
    ) {
      updateClientAppState({
        activeYear: [1986, 2023],
        timelineLimitsRange: [1986, 2023],
      });
    } else if (
      checkModuleKeyCondition('pasture:pasture_productivity')
    ) {
      updateClientAppState({
        activeYear: [2000, 2022],
        timelineLimitsRange: [2000, 2022],
      });
    } else if (
      checkModuleKeyCondition('pasture:pasture_age')
    ) {
      updateClientAppState({
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    } else if (
      checkModuleKeyCondition('pasture:pasture_vigor')
    ) {
      updateClientAppState({
        activeBaseMap: 1,
        layersOpacity: 100,
        activeLayers: ['state'],
        activeYear: [2000, 2023],
        timelineLimitsRange: [2000, 2023],
      });
    } else if (
      checkModuleKeyCondition('pasture:pasture_transitions')
    ) {
      updateClientAppState({
        activeYear: [1985, 2023],
        timelineLimitsRange: [1985, 2023],
      });
    }

    const parsedModuleContentKey = _.last(_.split(activeModuleContent, ':'));

    if (parsedModuleContentKey) {
      updateClientAppState({
        activeNoteKey: parsedModuleContentKey,
      });
    }

    setStatisticsData(null);
  }, [activeModuleContent]);

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    if (showBeforeAndAfterMosaic) {
      updateClientAppState({
        activeBaseMap: 8,
        layersOpacity: 50,
      });
    } else {
      updateClientAppState({
        layersOpacity: 100,
      });
    }
  }, [showBeforeAndAfterMosaic]);

  useEffect(() => {
    if (activeModule !== 'coverage' && mapboxMode) {
      toggleMapbox();
    }
  }, [activeModule]);

  useEffect(() => {
    const minYear = _.first(timelineLimitsRange);
    const maxYear = _.last(timelineLimitsRange);

    if (
      !_.isArray(activeYear) &&
      (activeYear < minYear || activeYear > maxYear)
    ) {
      updateClientAppState({
        activeYear: maxYear,
      });
    }
  }, [timelineLimitsRange]);

  useEffect(() => {
    if (isMobile) {
      updateClientAppState({
        headerIsVisible: false,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    if (territoryCategoriesList) {
      const countryTerritoryCategory = _.find(territoryCategoriesList, {
        key: 'country',
      });
      const countryTerritoryId = _.get(countryTerritoryCategory, 'id');
      loadCountryTerritoryData({
        variables: {
          categoryId: countryTerritoryId,
        },
      });
    }
  }, [territoryCategoriesList]);

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params,
        },
      },
    });
  };

  const closeClassLegendDialog = () => {
    updateClientAppState({ showClassInfo: null });
  };

  const closeDashboardDialog = () => {
    updateClientAppState({
      showDashboardInfo: null,
      transitionClassTreeNodeId: null,
    });
  };

  const closeMapInfoDialog = () => {
    updateClientAppState({ mapPointInfo: null });
  };

  const handleMapPointSearchClick = (mapPointInfo) => {
    updateClientAppState({ mapPointInfo });
  };

  const handleYearTimelineChange = (year) => {
    updateClientAppState({ activeYear: year });
  };

  const handleFlyToPointSubmit = (coordinates) => {
    updateClientAppState({ mapFlyTo: coordinates });
  };

  const handleFlyToReset = () => {
    updateClientAppState({ mapFlyTo: null });
  };

  const handleControlsChange = (paramKey, paramValue) => {
    updateClientAppState({ [paramKey]: paramValue });
  };

  const handleMoveEnd = (value) => {
    if (value !== mapPosition) {
      updateClientAppState({ mapPosition: value });
    }
  };

  const handleGeometryChange = (value) => {
    if (value) {
      updateClientAppState({
        geometry: JSON.stringify(value),
        geometryMode: null,
      });
    }
  };

  const openCoverageTutorial = () => {
    setCoverageTutorial(true);
  };

  const closeTutorial = () => {
    setCoverageTutorial(false);
  };

  const handleStatisticsShowData = (data) => {
    setStatisticsData(data);
  };

  const handleStatisticsCloseData = () => {
    setStatisticsData(null);
  };

  const toggleMapbox = () => {
    if (!mapboxMode) {
      updateClientAppState({
        activeBaseMap: 9,
        layersOpacity: 70,
        activeLayers: [],
        mapboxMode: !mapboxMode,
      });
    } else {
      updateClientAppState({
        mapboxMode: !mapboxMode,
      });
    }
  };

  const TimelineComponent =
    'temporal_analysis' === activeModule ||
    [
      'deforestation:deforestation_accumulated',
      'deforestation:deforestation_frequency',
    ].includes(activeModuleContent)
      ? YearTimelineMultiple
      : YearTimeline;

  return (
    <Fragment>
      {!mapboxMode && (
        <MapCanvas
          activeBaseMap={ activeBaseMap }
          activeModule={ activeModule }
          activeModuleContent={ activeModuleContent }
          activeYear={ activeYear }
          baseParams={ baseParams }
          carFeature={ carFeature }
          geometry={ geometry }
          geometryMode={ geometryMode }
          isOnUpdatingWarningMode={ isOnUpdatingWarningMode }
          configOptions={ mapConfigOptions }
          countryTerritoryId={ _.get(countryTerritoryListData, 'territoriesList[0].id') }
          defaultClassTreeLevelsList={ defaultClassTreeLevelsList }
          flyTo={ mapFlyTo }
          isMobile={ isMobile }
          mapPointInfo={ mapPointInfo }
          activeLayers={ activeLayers }
          layersOpacity={ layersOpacity }
          headerIsVisible={ headerIsVisible }
          onControlsChange={ handleControlsChange }
          onSearchPointClick={ handleMapPointSearchClick }
          showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
          showDashboardInfo={ showDashboardInfo }
          setCoverageTutorial={ setCoverageTutorial }
          onMoveEnd={ handleMoveEnd }
          onFlyToReset={ handleFlyToReset }
          mapboxMode={ mapboxMode }
          transitionClassTreeNodeId={ transitionClassTreeNodeId }
          ruralPropertyCode={ ruralPropertyCode }
          onCreateGeometry={ handleGeometryChange }
        />
      )}
      {mapboxMode && (
        <MapboxCanvas
          activeLayers={activeLayers}
          activeYear={activeYear}
          baseParams={baseParams}
          configOptions={mapConfigOptions}
          flyTo={mapFlyTo}
          headerIsVisible={headerIsVisible}
          isMobile={isMobile}
          layersOpacity={layersOpacity}
          onSearchPointClick={handleMapPointSearchClick}
          onMoveEnd={handleMoveEnd}
          geometry={ geometry }
          ruralPropertyCode={ ruralPropertyCode }
        />
      )}
      {!(showDashboardInfo && isMobile) && (
        <MapControls
          activeBaseMap={activeBaseMap}
          showBeforeAndAfterMosaic={showBeforeAndAfterMosaic}
          activeLayers={activeLayers}
          headerIsVisible={headerIsVisible}
          layersOpacity={layersOpacity}
          onChange={handleControlsChange}
        />
      )}
      { !activeSubmoduleHasNoTimeline &&
        !isOnUpdatingWarningMode &&
        !showBeforeAndAfterMosaic &&
        !(showDashboardInfo && isMobile) &&
        activeClassTreeOptionValue !== 'agriculture_irrigation_cultivation_frequency_average' &&
        <TimelineComponent
          activeModule={activeModule}
          firstYearFixed={_.includes(
            MODULES_WITH_INITIAL_YEAR_FIXED,
            activeModuleContent
          )}
          headerIsVisible={headerIsVisible}
          value={activeYear}
          onChange={handleYearTimelineChange}
        />
      }
      { activeClassTreeOptionValue === 'agriculture_irrigation_cultivation_frequency_average' &&
        <YearTimeline
          disabled
          activeModule={activeModule}
          firstYearFixed={_.includes(
            MODULES_WITH_INITIAL_YEAR_FIXED,
            activeModuleContent
          )}
          headerIsVisible={headerIsVisible}
          value={ [2017, 2023] }
          onChange={handleYearTimelineChange}
        />
      }
      <CARBox
        headerIsVisible={ headerIsVisible }
        showBeforeAndAfterMosaic={ showBeforeAndAfterMosaic }
        onFlyTo={ handleFlyToPointSubmit }
        onFeatureChange={ () => {} }
        updateClientAppState={ updateClientAppState }
      />
      <CoordinatesBox
        headerIsVisible={headerIsVisible}
        showBeforeAndAfterMosaic={showBeforeAndAfterMosaic}
        onSubmit={handleFlyToPointSubmit}
      />
      {activeModuleContent === 'coverage:coverage_main' && (
        <MapboxControl
          isActive={mapboxMode}
          onToggle={toggleMapbox}
          headerIsVisible={headerIsVisible}
          showBeforeAndAfterMosaic={showBeforeAndAfterMosaic}
        />
      )}
      <ShareControl
        headerIsVisible={headerIsVisible}
        showBeforeAndAfterMosaic={showBeforeAndAfterMosaic}
      />
      <ClassLegendDialog
        headerIsVisible={headerIsVisible}
        isOpen={!!showClassInfo}
        classId={showClassInfo}
        onClose={closeClassLegendDialog}
      />
      <MapPointDialog
        activeModule={activeModule}
        headerIsVisible={headerIsVisible}
        isOpen={!!mapPointInfo}
        pointInfo={mapPointInfo}
        updateClientAppState={updateClientAppState}
        onClose={closeMapInfoDialog}
      />
      <DashboardDialog
        activeModuleContent={activeModuleContent}
        activeYear={activeYear}
        baseParams={baseParams}
        classTreeKey={activeClassTreeOptionValue}
        headerIsVisible={headerIsVisible}
        isOpen={showDashboardInfo}
        onClose={closeDashboardDialog}
        onUpdateClientAppState={updateClientAppState}
      />
      {(activeModule === 'fire' ||
        activeModule === 'deforestation' ||
        activeModule === 'regeneration' ||
        activeModule === 'temporal_analysis' ||
        activeModule === 'agriculture' ||
        activeModule === 'soil' ||
        activeModule === 'degradation' ||
        activeModule === 'pasture' ||
        activeModuleContent === 'coverage:coverage_main' ||
        activeModuleContent === 'coverage:coverage_stable_areas' ||
        activeModuleContent === 'coverage:coverage_number_of_classes' ||
        activeModuleContent === 'mining:mining_main' ||
        activeModuleContent === 'sentinel:sentinel_coverage' ||
        activeModuleContent === 'urban:urban_coverage') && !isOnUpdatingWarningMode && (
        <StatisticsBox
          activeModule={activeModule}
          activeModuleContent={activeModuleContent}
          activeYear={activeYear}
          baseParams={baseParams}
          classTreeKey={activeClassTreeOptionValue}
          statisticsDataIsVisible={!!statisticsData}
          headerIsVisible={headerIsVisible}
          onShowData={handleStatisticsShowData}
          ruralPropertyCode={ ruralPropertyCode }
          geometry={ geometry }
        />
      )}
      {activeModuleContent === 'infrastructure:infrastructure_main' && (
        <InfrastructureStatisticsBox
          activeYear={activeYear}
          baseParams={baseParams}
          statisticsDataIsVisible={!!statisticsData}
          headerIsVisible={headerIsVisible}
          onShowData={handleStatisticsShowData}
        />
      )}
      <StatisticsDataTableBox
        headerIsVisible={headerIsVisible}
        data={statisticsData}
        onClose={handleStatisticsCloseData}
      />

      {/* <TutorialTour currentModule={activeModule} closeTutorial={closeTutorial} runTutorial={runTutorial} /> */}

      <Dialog onClose={() => {}} open={blockModuleWarningIsVisible}>
        <div className={styles.tempDialog}>
          <p>Os dados estão sendo atualizados.</p>
          <Button
            variant='contained'
            size='small'
            onClick={() => (window.location.href = '/')}
          >
            <span>Voltar para página inicial</span>
          </Button>
        </div>
      </Dialog>
    </Fragment>
  );
}
