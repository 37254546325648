import { gql } from 'graphql.macro';

export const GET_BASE_DATA = gql`
  {
    app @client {
      activeNoteKey
      updatingWarningContentKey
    }
  }
`;
